
.csvDownload{
    box-shadow: inset 0px 1px 0px 0px #e184f3;
    background-color: red;
    border-radius: 6px;
    border: red;
    display: inline-block;
    cursor: pointer; 
    color: #ffffff;
    font-size: 15px;
    padding: 6px 24px;
    height: 40px;
    line-height: middle;
    margin-top: 12px;
    width: 320px;
    text-decoration: none;
    text-align: center;
}