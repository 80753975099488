/*table, th, td {
  border: 2px solid black;
  margin-top: 40px;
}*/

#root {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  height: 100vh;
  /* margin: 85px 0 0 0; */
}

.margem-top {
  margin-top: 85px !important;
}
.footer_landing {
  margin: auto 0;
}

select {
  display: inline-block !important;
  width: 150px;
}

.products {
  margin: 0 auto;
  width: 80%;
}

.seletor {
  color: black;
  font-size: 16px;
  font-weight: bold;
}

a {
  font-size: 30px;
}

.opcao {
  margin-bottom: 10px;
  padding: 10px;
}

header {
  margin-bottom: 45px;
}

option {
  font-size: 12px !important;
}

.search {
  position: relative;
}

.search i {
  position: absolute;
  right: 15px;
  top: 45px;
}

.switch {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
}

.material-icons {
  margin-left: 12px;
}

.fixed {
  position: fixed;
  z-index: 9;
}

.col {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  list-style-type: none;
}
